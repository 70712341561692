import { BreakpointObserver } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injectable, inject } from '@angular/core';
import { Observable, fromEvent, map, startWith } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WindowsSizeService {
    private breakpointObserver = inject(BreakpointObserver);
    private _platform = inject(Platform);

    checkMaxScreenSize(sizeValidate: number): boolean {
        if (
            this.breakpointObserver.isMatched(
                `(max-width: ${sizeValidate.toString()}px)`,
            )
        )
            return true;

        return false;
    }

    checkMinScreenSize(sizeValidate: number): boolean {
        if (
            this.breakpointObserver.isMatched(
                `(min-width: ${sizeValidate.toString()}px)`,
            )
        )
            return true;

        return false;
    }

    getWindowResizeObservable(): Observable<number> {
        if (this._platform.isBrowser) {
            return fromEvent(window, 'resize').pipe(
                startWith(window.innerWidth),
                map(() => window.innerWidth),
            );
        } else {
            return new Observable<number>(() => {});
        }
    }
}
